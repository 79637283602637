import Vue from "vue";
import { WebApiAdaptor } from "@syncfusion/ej2-data";

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

class DrfAdaptorNew extends WebApiAdaptor {
  beforeSend(dm, request) {
    const token = Vue.auth.token();
    request.setRequestHeader(
      "Accept",
      "application/json, text/javascript, */*; q=0.01"
    );
    request.setRequestHeader("Authorization", `JWT ${token}`);
    request.setRequestHeader("x-csrftoken", getCookie("csrftoken"));
  }
  update(dm, keyField, value) {
    return {
      type: "PATCH",
      url: dm.dataSource.url + value.id + "/",
      data: JSON.stringify(value),
    };
  }
  remove(dm, keyField, value) {
    return {
      type: "DELETE",
      url: dm.dataSource.url + value + "/",
      data: JSON.stringify(value),
    };
  }
  processResponse(data, ds, query, xhr, request, changes) {
    data = data.payload || {};
    return super.processResponse(data, ds, query, xhr, request, changes);
  }
}

export default DrfAdaptorNew;
