import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.js";
import toaster from "./toaster.js";
import connections from "./connections.js";
import service from "./service.js";
import shop from "./shop.js";
import rounds from "./rounds.js";
import orders from "./orders.js";
import incidents from "./incidents.js";
import messages from "./messages.js";
import productWeight from "./productWeight.js";
import invoices from "./invoices.js";
Vue.use(Vuex);

const initialState = () => {
  return {
    sidebarShow: "responsive",
    sidebarMinimize: false,
    asideShow: false,
    cartShow: false,
    darkMode: false,
    role: {},
  };
};

const state = initialState();

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle(state, variable) {
    state[variable] = !state[variable];
  },
  setRole(state, role) {
    state.role = role;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  resetState({ commit }) {
    commit("auth/resetState");
    commit("connections/resetState");
    commit("toaster/resetState");
    commit("service/resetState");
    commit("shop/resetState");
    commit("rounds/resetState");
    commit("orders/resetState");
    commit("incidents/resetState");
    commit("messages/resetState");
    commit("productWeight/resetState");
    commit("invoices/resetState");
    commit("resetState");
  },
};

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    auth,
    toaster,
    connections,
    service,
    shop,
    rounds,
    orders,
    incidents,
    messages,
    productWeight,
    invoices,
  },
});
Vue.store = store;
export default store;
