import axios from "./axios";
import auth from "./auth";
import coreui from "./coreui";
import syncfusion from "./syncfusion";
import cldrData from "./cldr-data";
import fontAwesome from "./font-awesome";
import vuelidate from "./vuelidate";
import recaptcha from "./recaptcha";
import components from "./components";
import i18n from "./i18n";
import icons from "./icons";
import leaflet from "./leaflet";

const config = (vue) => {
  axios(vue);
  auth(vue);
  coreui(vue);
  syncfusion(vue);
  cldrData(vue);
  fontAwesome(vue);
  vuelidate(vue);
  recaptcha(vue);
  const i18nInstace = i18n(vue);
  const iconsData = icons(vue);

  // components
  components(vue);

  leaflet(vue);

  return { i18n: i18nInstace, icons: iconsData };
};

export default config;
