import { GetDataManagerNew, ApiCall, Query } from "../ds";
import get from "lodash/get";

const initialState = () => {
  return {
    cartUpdating: false,
    market: undefined,
    cart: undefined,
  };
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    setCartUpdating(state, status) {
      state.cartUpdating = status;
    },

    setMarket: (state, market) => {
      state.market = market;
    },

    setCart: (state, cart) => {
      state.cart = cart;
    },

    resetState(state) {
      Object.assign(state, initialState());
    },
  },

  actions: {
    loadCart({ commit, state, rootState }) {
      const familyId = get(rootState, "connections.current.family.id", null);
      const marketId = get(state, "market.market.id", null);
      if (!familyId || !marketId) {
        return Promise.reject(new Error(`familyId or marketId missing`));
      }

      return ApiCall(
        GetDataManagerNew("family_market_current_cart", [familyId, marketId]),
        new Query(),
        function (e) {
          commit("setCart", e.result);
          return e;
        }
      );
    },

    setCartQuantity({ commit, state, rootState }, payload) {
      const familyId = get(rootState, "connections.current.family.id", null);
      const marketId = payload.marketId
        ? payload.marketId
        : get(state, "market.market.id", null);

      if (!familyId || !marketId) {
        return Promise.reject(new Error(`familyId or marketId missing`));
      }

      const ppms = get(payload, "items", []).map((el) => {
        return {
          ppm_id: parseInt(el.ppm_id, 10),
          qta: parseFloat(el.quantity),
        };
      });

      const params = { ppms };
      commit("setCartUpdating", true);
      return GetDataManagerNew("family_market_current_cart", [
        familyId,
        marketId,
      ])
        .insert(params)
        .then((response) => {
          commit("setCartUpdating", false);
          if (get(state, "market.market.id", null) === response.market) {
            commit("setCart", response);
          }
          return response;
        })
        .catch((response) => {
          commit("setCartUpdating", false);
          console.log(`Error updating marker cart`);
          console.error(response);
          return response;
        });
    },
  },
};
