const initialState = () => {
  return {
    variants: [],
  };
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    selectedVariants: (state, data) => {
      state.variants = data;
    },
    resetState(state) {
      Object.assign(state, initialState());
    },
  },
};
