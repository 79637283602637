const initialState = () => {
  return {
    toasts: [],
  };
};

export default {
  namespaced: true,

  state: () => {
    return initialState();
  },

  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
  },

  actions: {
    add({ state }, data) {
      let toastConfig = {
        autohide: data.autohide ? 5000 : false,
        closeButton: true,
        fade: true,
        position: "bottom-right",
        header: data.title || "title",
        text: data.text || "message",
        color: data.color || undefined,
      };
      // console.log(toastConfig)
      state.toasts.push(toastConfig);
    },
  },
};
