import Vue from "vue";
import config from "./config";
import App from "./App";
import router from "./router/index";
import store from "./store";
const configs = config(Vue);
import "leaflet/dist/leaflet.css";

new Vue({
  el: "#app",
  router,
  store,
  ...configs,
  template: "<App/>",
  components: {
    App,
  },
});
