import get from "lodash/get";

export const PERMISSIONS_KEY = {
  DATA_CONTACTS: "role_dc",
  LOGISTICS: "role_lg",
  ORDERS_AND_REPORTS: "role_os",
  PRODUCTS_AND_PRICES: "role_pp",
  REPORTING: "role_exp",
  FINANCE_AND_ACCOUNTING: "role_fin",
};

export const PERMISSIONS_MOD = {
  NOACCESS: 0,
  READ: 1,
  READ_WRITE: 2,
};

export const ROLE_TYPE = {
  MARKET: 1,
  PRODUCER: 2,
  PRODUCER_ISLAND: 3,
  ISLAND: 4,
  SHOP: 5,
};

export const haveRole = (connection) => {
  return connection && connection.family && connection.family.role;
};

export const isActive = (connection) => {
  return (
    connection && connection.family && connection.family.is_active === true
  );
};

export const PERMS = Object.freeze({
  DATA_CONTACTS_VIEW: "DATA_CONTACTS_VIEW",

  LOGISTICS_VIEW: "LOGISTICS_VIEW",
  LOGISTICS_EDIT: "LOGISTICS_EDIT",

  ORDERS_VIEW: "ORDERS_VIEW",
  ORDERS_EDIT: "ORDERS_EDIT",
  ORDERS_ROUND_MANAGEMENT: "ORDERS_ROUND_MANAGEMENT",
  ORDERS_DDMP_MANAGEMENT: "ORDERS_DDMP_MANAGEMENT",
  ORDERS_DDMI_MANAGEMENT: "ORDERS_DDMI_MANAGEMENT",

  PRODUCTS_AND_PRICES_VIEW: "PRODUCTS_AND_PRICES_VIEW",
  PRODUCTS_AND_PRICES_MARKET_VIEW: "PRODUCTS_AND_PRICES_MARKET_VIEW",
  PRODUCTS_AND_PRICES_MARKET_EDIT: "PRODUCTS_AND_PRICES_MARKET_EDIT",

  REPORTING_VIEW: "REPORTING_VIEW",

  FINANCE_AND_ACCOUNTING_VIEW: "FINANCE_AND_ACCOUNTING_VIEW",
});

export const buildConnectionPermissions = (connection) => {
  const permissions = {};

  const connectionPerms = connection.permissions;
  const roleType = get(connection, "family.role.r_type", 0);

  for (const PERM in PERMS) {
    let value = false;
    switch (PERM) {
      // data contacts
      case PERMS.DATA_CONTACTS_VIEW:
        value =
          connectionPerms[PERMISSIONS_KEY.DATA_CONTACTS] >
          PERMISSIONS_MOD.NOACCESS;
        break;

      // logistics
      case PERMS.LOGISTICS_VIEW:
        value =
          connectionPerms[PERMISSIONS_KEY.LOGISTICS] > PERMISSIONS_MOD.NOACCESS;
        break;

      case PERMS.LOGISTICS_EDIT:
        value =
          connectionPerms[PERMISSIONS_KEY.LOGISTICS] > PERMISSIONS_MOD.READ;
        break;

      // orders
      case PERMS.ORDERS_VIEW:
        value =
          connectionPerms[PERMISSIONS_KEY.ORDERS_AND_REPORTS] >
          PERMISSIONS_MOD.NOACCESS;
        break;
      case PERMS.ORDERS_EDIT:
        value =
          connectionPerms[PERMISSIONS_KEY.ORDERS_AND_REPORTS] >
          PERMISSIONS_MOD.READ;
        break;
      case PERMS.ORDERS_ROUND_MANAGEMENT:
        value =
          connectionPerms[PERMISSIONS_KEY.ORDERS_AND_REPORTS] >
            PERMISSIONS_MOD.NOACCESS &&
          [ROLE_TYPE.MARKET, ROLE_TYPE.SHOP].includes(roleType);
        break;
      case PERMS.ORDERS_DDMP_MANAGEMENT:
        value =
          connectionPerms[PERMISSIONS_KEY.ORDERS_AND_REPORTS] >
            PERMISSIONS_MOD.NOACCESS &&
          [ROLE_TYPE.PRODUCER, ROLE_TYPE.PRODUCER_ISLAND].includes(roleType);
        break;
      case PERMS.ORDERS_DDMI_MANAGEMENT:
        value =
          connectionPerms[PERMISSIONS_KEY.ORDERS_AND_REPORTS] >
            PERMISSIONS_MOD.NOACCESS &&
          [ROLE_TYPE.ISLAND, ROLE_TYPE.PRODUCER_ISLAND].includes(roleType);
        break;

      // products_and_prices
      case PERMS.PRODUCTS_AND_PRICES_VIEW:
        value =
          connectionPerms[PERMISSIONS_KEY.PRODUCTS_AND_PRICES] >
            PERMISSIONS_MOD.NOACCESS && ![ROLE_TYPE.ISLAND].includes(roleType);
        break;

      case PERMS.PRODUCTS_AND_PRICES_MARKET_VIEW:
          console.log("PRODUCTS_AND_PRICES_MARKET_VIEW", [ROLE_TYPE.MARKET].includes(roleType), ROLE_TYPE.MARKET, roleType)
          value =
            connectionPerms[PERMISSIONS_KEY.PRODUCTS_AND_PRICES] >
              PERMISSIONS_MOD.NOACCESS && [ROLE_TYPE.MARKET].includes(roleType);
          console.log(value)
          break;

      case PERMS.PRODUCTS_AND_PRICES_MARKET_EDIT:
          value =
            connectionPerms[PERMISSIONS_KEY.PRODUCTS_AND_PRICES] >
              PERMISSIONS_MOD.READ && [ROLE_TYPE.MARKET].includes(roleType);
          break;

      // reporting
      case PERMS.REPORTING_VIEW:
        value =
          connectionPerms[PERMISSIONS_KEY.REPORTING] > PERMISSIONS_MOD.NOACCESS;
        break;

      // finance and accounting
      case PERMS.FINANCE_AND_ACCOUNTING_VIEW:
        value =
          connectionPerms[PERMISSIONS_KEY.FINANCE_AND_ACCOUNTING] >
          PERMISSIONS_MOD.NOACCESS;
        break;

      default:
        value = false;
    }

    permissions[PERM] = value;
  }

  return Object.freeze(permissions);
};

export const isAllowed = (permissions, perm) => {
  return get(permissions, perm, false);
};
