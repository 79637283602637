import Flickity from "vue-flickity";
require("flickity-as-nav-for");
require("flickity-imagesloaded");
import VueLadda from "../common/vue-ladda";
import DropdownMenu from "../common/DropdownMenu";
import { Fragment } from "vue-frag";

const initialize = (vue) => {
  vue.component("flickity", Flickity);
  vue.component("vue-ladda", VueLadda);
  vue.component("dropdown-menu", DropdownMenu);
  vue.component("Fragment", Fragment);
};

export default initialize;
