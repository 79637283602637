import Vue from "vue";
import auth from "@websanova/vue-auth/dist/v2/vue-auth.esm.js";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js";
import driverOAuth2Google from "@websanova/vue-auth/dist/drivers/oauth2/google.js";
import driverOAuth2Facebook from "@websanova/vue-auth/dist/drivers/oauth2/facebook.js";

driverOAuth2Facebook.params.client_id = window.env.FACEBOOK_APP_ID;
driverOAuth2Facebook.params.redirect_uri = "/login/social/facebook";

driverOAuth2Google.params.client_id = window.env.GOOGLE_APP_ID;
driverOAuth2Google.params.redirect_uri = "/login/social/google";

const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

const authJWT = {
  request: function (req, token) {
    if (req.url == "api/account/jwt/refresh_token/") {
      req.data = {
        token: token,
      };
    }
    this.drivers.http.setHeaders.call(this, req, {
      Authorization: "JWT " + token,
    });
    if (req.method == "post") {
      this.drivers.http.setHeaders.call(this, req, {
        "x-csrftoken": getCookie("csrftoken"),
      });
    }
  },

  response: function (res) {
    if (res && res.data && res.data.token) {
      // console.log(res.data.token)
      return res.data.token;
    }
  },
};

const LoonityparseUserData = (data) => {
  return data || {};
};

const init = (instace) => {
  instace.use(auth, {
    plugins: {
      http: Vue.axios,
      router: Vue.router,
    },
    drivers: {
      auth: authJWT,
      http: driverHttpAxios,
      router: driverRouterVueRouter,
      oauth2: {
        google: driverOAuth2Google,
        facebook: driverOAuth2Facebook,
      },
    },
    options: {
      authRedirect: {
        path: "/public/login",
      },
      notFoundRedirect: {
        path: "/dashboard",
      },
      loginData: {
        url: "api/account/jwt/obtain_token/",
        method: "POST",
        redirect: "/dashboard",
        fetchUser: true,
        staySignedIn: true,
      },
      fetchData: {
        url: "api/account/user/",
        method: "GET",
        enabled: true,
      },
      refreshData: {
        url: "api/account/jwt/refresh_token/",
        method: "POST",
        enabled: true,
        interval: 30,
      },
      logoutData: {
        url: "api/account/logout/",
        method: "POST",
        redirect: "/public/login",
        makeRequest: true,
      },
      parseUserData: LoonityparseUserData,
    },
  });
};

export default init;
