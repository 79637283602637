import Vue from "vue";
import { INCIDENT_ENTITY_TYPE } from "../config/global";
import { endPoint } from "../ds";

const initialState = () => {
  return {
    create: {
      show: false,
      order: null,
      details: [],
    },
  };
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    closeModal(state) {
      state.create.show = false;
    },

    openModal(state, payload) {
      const { order, show, details } = payload;
      state.create.order = order;
      state.create.details = details;
      if (show === true) {
        state.create.show = true;
      }
    },

    resetState(state) {
      Object.assign(state, initialState());
    },
  },

  actions: {
    createMessage(context, { familyId, message, details, messageType }) {
      const url = endPoint("role_order_fincident");

      const formData = {
        family_id: familyId,
        msg: message,
        message_type: messageType,
      };

      formData[INCIDENT_ENTITY_TYPE.DETAILS] = details;

      return Vue.axios.post(url, formData);
    },
  },

  getters: {},
};
