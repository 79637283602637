<template>
  <div>
    <router-view v-if="ready"></router-view>
    <p v-else>Loading 2...</p>
    <CToaster
      v-for="(toaster, toasterKey) in toasters"
      :position="toasterKey"
      :key="'toaster' + toasterKey"
    >
      <template v-for="(toast, key) in toaster">
        <CToast :key="'toast' + key" :show="true" v-bind="toast">
          {{ toast.text }}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import { setCulture } from '@syncfusion/ej2-base'

export default {
  name: "App",
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    toasters() {
      let res = this.$store.state.toaster.toasts.reduce((toasters, toast) => {
        toasters[toast.position] = toasters[toast.position] || [];
        toasters[toast.position].push(toast);
        return toasters;
      }, {});
      return res;
    },
    user_lang() {
      var user_data = this.$auth.user() || {language:null};
      return user_data.language
    }
  },
  created() {
    this.ready = true;
  },
  watch: {
    user_lang: function (newLang, oldLang) {
      if (newLang && this.$i18n.locale !== newLang) {
        this.$i18n.locale = newLang;
        setCulture(newLang)
      }
    }
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
