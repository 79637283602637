import {
  L10n,
  setCulture,
  setCurrencyCode,
  loadCldr,
} from "@syncfusion/ej2-base";
import lang_it from "@syncfusion/ej2-locale/src/it.json";
import lang_en from "@syncfusion/ej2-locale/src/en-US.json";
import lang_de from "@syncfusion/ej2-locale/src/de.json";

import numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import gregorian from "cldr-data/main/it/ca-gregorian.json";
import numbers from "cldr-data/main/it/numbers.json";
import currencies from "cldr-data/main/it/currencies.json";
import timeZoneNames from "cldr-data/main/it/timeZoneNames.json";
import weekData from "cldr-data/supplemental/weekData.json"; // To load the culture based first day of week

const initialize = () => {
  L10n.load(lang_it);
  L10n.load(lang_en);
  L10n.load(lang_de);
  loadCldr(
    numberingSystems,
    gregorian,
    numbers,
    timeZoneNames,
    currencies,
    weekData
  );
  // setCulture("it");
  // setCurrencyCode("EUR");
  setCurrencyCode("CHF");
};

export default initialize;
