import Vue from "vue";

const initialState = () => {
  return {};
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
  },

  actions: {
    fetch(data) {
      return Vue.auth.fetch(data);
    },

    refresh(data) {
      return Vue.auth.refresh(data);
    },

    login(ctx, data) {
      data = data || {};
      var redirectObj = Vue.auth.redirect();
      return new Promise((resolve, reject) => {
        Vue.auth
          .login({
            // url: 'auth/login',
            // body: data.body, // VueResource
            data: data.body, // Axios
            remember: data.remember,
            staySignedIn: data.staySignedIn,
            redirect: {
              path: redirectObj ? redirectObj.from.path : "/",
            },
          })
          .then((res) => {
            if (data.remember) {
              Vue.auth.remember(
                JSON.stringify({
                  name: ctx.getters.user.first_name,
                })
              );
            }

            resolve(res);
          }, reject);
      });
    },

    register(ctx, data) {
      data = data || {};

      return new Promise((resolve, reject) => {
        Vue.auth
          .register({
            url: "auth/register",
            body: data.body, // VueResource
            data: data.body, // Axios
            autoLogin: false,
          })
          .then(() => {
            if (data.autoLogin) {
              ctx.dispatch("login", data).then(resolve, reject);
            }
          }, reject);
      });
    },

    impersonate(ctx, data) {
      Vue.auth.impersonate({
        url: "auth/" + data.user.id + "/impersonate",
        redirect: "user-account",
      });
    },

    unimpersonate() {
      Vue.auth.unimpersonate({
        redirect: "admin-users",
      });
    },

    logout() {
      return Vue.auth.logout();
    },
  },

  getters: {
    user() {
      return Vue.auth.user();
    },

    impersonating() {
      return Vue.auth.impersonating();
    },
  },
};
