import Vue from "vue";
import { INCIDENT_TYPE, INCIDENT_ENTITY_TYPES } from "../config/global";
import { endPoint } from "../ds";

const initiaEntities = () => {
  const tmp = {};
  INCIDENT_ENTITY_TYPES.forEach((type) => (tmp[type] = []));
  return tmp;
};

const initialState = () => {
  return {
    create: {
      show: false,
      type: INCIDENT_TYPE.INCIDENT,
      entities: initiaEntities(),
    },
    edit: {
      show: false,
      incidentDetail: null,
    },
    view: {
      show: false,
      incidentDetail: null,
    },
    message: {
      show: false,
      incident_id: null,
    },
    batch: {
      show: false,
      action: null,
      details: [],
    },
  };
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    createShow(state) {
      state.create.show = true;
    },

    createHide(state) {
      state.create.show = false;
    },

    setIncidentType(state, type) {
      state.create.type = type;
    },

    createAddEntities(state, payload) {
      const { entities, show, type } = payload;
      if (Array.isArray(entities)) {
        entities.forEach((record) => {
          const { type, items } = record;
          if (INCIDENT_ENTITY_TYPES.includes(type)) {
            state.create.entities[type] = [
              ...state.create.entities[type],
              ...items,
            ];
          }
        });
      }

      if (type) {
        state.create.type = type;
      }

      if (show === true) {
        state.create.show = true;
      }
    },

    createResetEntities(state, payload) {
      const { type } = payload;
      if (INCIDENT_ENTITY_TYPES.includes(type)) {
        state.create.entities[type] = [];
      }
    },

    createResetAllEntities(state) {
      state.create.entities = initiaEntities();
    },

    editShow(state) {
      state.edit.show = true;
    },

    editHide(state) {
      state.edit.show = false;
    },

    editSetIncident(state, data) {
      state.edit.incident = data;
    },

    editSetIncidentDetail(state, data) {
      state.edit.incidentDetail = data;
    },

    editResetDetail(state) {
      state.edit.detail = null;
    },

    batchShow(state) {
      state.batch.show = true;
    },

    batchHide(state) {
      state.batch.show = false;
    },

    batchAction(state, payload) {
      const { action, details, show } = payload;
      state.batch.action = action;
      state.batch.details = details;
      if (show === true) {
        state.batch.show = true;
      }
    },

    viewShow(state) {
      state.view.show = true;
    },

    viewHide(state) {
      state.view.show = false;
    },

    viewSetIncidentDetail(state, data) {
      state.view.incidentDetail = data;
    },

    messageShow(state, payload) {
      const { incident_id } = payload;
      state.message.show = true;
      state.message.incident_id = incident_id;
    },

    messageHide(state) {
      state.message.show = false;
    },

    resetState(state) {
      Object.assign(state, initialState());
    },
  },

  actions: {
    createIncident({ state }, { roleId, message }) {
      const url = endPoint("role_order_incident");
      const { entities } = state.create;

      const formData = {
        role_id: roleId,
        msg: message,
      };

      Object.keys(entities).forEach((type) => {
        const values = entities[type];
        if (Array.isArray(values) && values.length > 0) {
          formData[type] = values.map((item) => item.id);
        }
      });

      return Vue.axios.post(url, formData);
    },

    createCancellation({ state }, { roleId, message }) {
      const url = endPoint("role_order_cancellation");
      const { entities } = state.create;

      const formData = {
        role_id: roleId,
        msg: message,
      };

      Object.keys(entities).forEach((type) => {
        const values = entities[type];
        if (Array.isArray(values) && values.length > 0) {
          formData[type] = values.map((item) => item.id);
        }
      });

      return Vue.axios.post(url, formData);
    },

    editIncident(context, formData) {
      const url = endPoint("role_orders_incident_manager");
      return Vue.axios.patch(url, formData);
    },

    batchAction(context, formData) {
      const url = endPoint("role_orders_incident_multimanager");
      return Vue.axios.patch(url, formData);
    },

    toggleIncident(context, formData) {
      const url = endPoint("role_orders_incident_manager");
      return Vue.axios.post(url, formData);
    },

    messageAdd(context, formData) {
      // formData: incident_id, msg
      const url = endPoint("role_orders_incident_manager");
      return Vue.axios.post(url, formData);
    },
  },

  getters: {},
};
