import Vue from "vue";
import { endPoint } from "../ds";
import {
  getOrderStatusOptions,
  getOrderLineOptions,
  getLogisticTypeOptions,
} from "../helpers/options";

const initialFilters = () => {
  return {
    keyword: "",
    statusId: "",
    productTypeId: "",
    productLineId: "",

    // pin filters
    producerId: null,
    productId: null,
    orderId: null,
    islandId: null,

    options: {
      status: [],
      productLine: [],
      productType: [],
    },
  };
};

const initialState = () => {
  return {
    view: "ddmp",
    filters: initialFilters(),
  };
};

const availableViews = ["ddmp", "ddmi", "incident"];

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    setView(state, view) {
      if (availableViews.includes(view)) {
        state.view = view;
      } else {
        console.warn(`view "${view}" not available`);
      }
    },

    updateKeyword(state, value) {
      state.filters.keyword = value;
    },

    updateStatusId(state, value) {
      state.filters.statusId = value;
    },

    updateProductTypeId(state, value) {
      state.filters.productTypeId = value;
    },

    updateProductLineId(state, value) {
      state.filters.productLineId = value;
    },

    // pin filters
    updateProducerId(state, value) {
      state.filters.producerId = value;
    },

    updateProductId(state, value) {
      state.filters.productId = value;
    },

    updateOrderId(state, value) {
      state.filters.orderId = value;
    },

    updateIslandId(state, value) {
      state.filters.islandId = value;
    },

    setFilterOptions(state, { key, options }) {
      state.filters.options[key] = options;
    },

    resetMainFilters(state) {
      state.filters.keyword = "";
      state.filters.statusId = "";
      state.filters.productTypeId = "";
      state.filters.productLineId = "";
    },

    resetPinFilter(state, filters = []) {
      for (const filter of filters) {
        state.filters[filter] = null;
      }
    },

    resetPinFilters(state) {
      const filters = ["producerId", "productId", "orderId", "islandId"];
      for (const filter of filters) {
        state.filters[filter] = null;
      }
    },

    resetState(state) {
      Object.assign(state, initialState());
    },

    resetFilters(state) {
      state.filters = initialFilters();
    },
  },

  actions: {
    initFilters({ commit }, { lang, i18n }) {
      commit("resetFilters");
      commit("setFilterOptions", {
        key: "status",
        options: [...getOrderStatusOptions(i18n)],
      });
      commit("setFilterOptions", {
        key: "productLine",
        options: [...getOrderLineOptions(i18n)],
      });

      return new Promise((resolve) => {
        resolve();
        const locale = lang.locale;
        Promise.all([getLogisticTypeOptions({ lang })]).then((values) => {
          commit("setFilterOptions", {
            key: "productType",
            options: [...values[0]],
          });
          resolve();
        });
      });
    },

    updateStatusBatch(context, formData) {
      const url = endPoint("role_orders_manager");
      return Vue.axios.patch(url, formData);
    },
  },

  getters: {
    havePinFilters(state) {
      return (
        state.filters.producerId !== null ||
        state.filters.productId !== null ||
        state.filters.orderId !== null ||
        state.filters.islandId !== null
      );
    },

    pinFilters(state) {
      const { producerId, productId, orderId, islandId } = state.filters;
      return {
        producerId,
        productId,
        orderId,
        islandId,
      };
    },
  },
};
