<template>
  <div class="ui-dropdown-menu">
    <div
      class="ui-dropdown-menu__trigger"
      :class="{ 'ui-dropdown-menu__trigger--open': isVisible }"
      @click="open"
      ref="trigger"
      v-on-clickaway="close"
    >
      <slot />
    </div>

    <ul class="ui-dropdown-menu__items" ref="menu" v-show="isVisible">
      <li
        v-for="item of items"
        :key="item.id"
        @click.prevent="(e) => onItemClick(item, e)"
        class="ui-dropdown-menu__item"
        :class="{ 'ui-dropdown-menu__item--disabled': item.disabled === true }"
      >
        {{ item.text }}
      </li>
    </ul>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { directive as onClickaway } from "vue-clickaway";

export default {
  name: "DropdownMenu",

  directives: {
    onClickaway: onClickaway,
  },

  props: {
    boundariesElement: {
      type: String,
      default: "body",
    },

    items: {
      type: Array,
      default: () => [],
    },

    select: {
      type: Function,
      required: true,
      default: null,
    },

    placement: {
      type: String,
      default: "bottom-end",
    },
  },

  data() {
    return {
      opened: false,
    };
  },

  computed: {
    isVisible() {
      return this.opened;
    },
  },

  beforeDestroy() {
    if (this.popper !== undefined) {
      this.popper.destroy();
    }
  },

  methods: {
    open(evt) {
      document.querySelector(this.boundariesElement).click();

      this.opened = true;

      if (this.popper) {
        this.popper.destroy();
      }

      this.popper = createPopper(this.$refs.trigger, this.$refs.menu, {
        strategy: "fixed",
        placement: this.placement,
        // modifiers: [
        //   {
        //     name: "preventOverflow",
        //     options: {
        //       boundary: document.querySelector(this.boundariesElement),
        //       altBoundary: true,
        //     },
        //   },
        // ],
      });
    },

    close() {
      if (this.opened) {
        this.opened = false;
        if (this.popper) {
          this.popper.destroy();
        }
      }
    },

    onItemClick(item, e) {
      if (typeof this.select === "function" && !item.disabled) {
        this.select({ item, event: e });
        this.close();
      }
    },
  },
};
</script>
