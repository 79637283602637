import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = window.env.LOONITY_BASE_API_URL;
Vue.use(VueAxios, axios);

export const apiUrl = (path) => {
  const baseUrl = window.env.LOONITY_BASE_API_URL;
  if (!baseUrl) {
    throw new Error(
      "You must define on .env file a VUE_APP_LOONITY_BASE_API_URL pointing at main API endpoint"
    );
  }
  return `${baseUrl}${path}`;
};

export default {
  // root: window.env.API_URL
};
