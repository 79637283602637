import Vue from "vue";
import { endPoint } from "../ds";

const initialState = () => {
  return {
    show: false,
    entryData: null,
  };
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    closeModal(state) {
      state.show = false;
      state.entryData = null;
    },

    openModal(state, payload) {
      const { entryData } = payload;
      state.entryData = entryData;
      state.show = true;
    },

    resetState(state) {
      Object.assign(state, initialState());
    },
  },

  actions: {
    editWeight(context, payload) {
      const url = endPoint("role_order_pw");
      return Vue.axios.patch(url, payload);
    },
  },

  getters: {},
};
