import { ApiCall } from "../../ds/index";
import { Query } from "@syncfusion/ej2-data";

let query_cache = {};
let reducer_cache = {};

var _ = require("lodash");

const base_config = {
  dm: null,
  query: new Query(),
  query_cache: null,
  reducer: function (map, obj) {
    map.push({
      value: obj.id,
      label: obj.name,
    });
    return map;
  },
  reducer_cache: null,
};

async function GetJson(config) {
  let res = null;
  await ApiCall(config.dm, config.query, function (e) {
    res = e.result;
  });
  if (config.query_cache) {
    query_cache[config.query_cache] = res;
  }
  return res;
}

async function GetResults(config) {
  // verifica se sono richiesti i valori in cache, altrimenti richiedili on the fly
  if (config.query_cache) {
    return query_cache[config.query_cache] || (await GetJson(config));
  } else {
    return await GetJson(config);
  }
}

async function DoReduce(config) {
  // calcola il risultato
  var query_result = await GetResults(config);
  let res = query_result.reduce(config.reducer, []);
  // se è necessario salvalo in cache
  if (config.reducer_cache) {
    reducer_cache[config.reducer_cache] = res;
  }
  // console.log("DoReduce", res)
  return res;
}

async function GetOptions(u_config) {
  // console.log("GetOptions Base", u_config)
  let config = _.merge(Object.assign({}, base_config), u_config);
  // console.log(u_config.cur_lang && !u_config.reducer)
  if (u_config.cur_lang && !u_config.reducer) {
    config.reducer = function (map, obj) {
      map.push({
        value: obj.id,
        label: obj.translations[config.cur_lang].name,
      });
      return map;
    };
  }
  // console.log("GetOptions", config)
  // check il result cache is required, otherwise generate on the fly
  if (config.reducer_cache) {
    // console.log("Reducer cache", reducer_cache[config.reducer_cache])
    return reducer_cache[config.reducer_cache] || (await DoReduce(config));
  } else {
    return await DoReduce(config);
  }
}

export default GetOptions;
