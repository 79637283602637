import { VueReCaptcha } from "vue-recaptcha-v3";

const init = (vue) => {
  vue.use(VueReCaptcha, {
    siteKey: window.env.GOOGLE_RECAPTCHA_KEY,
    loaderOptions: { autoHideBadge: true },
  });
};

export default init;
