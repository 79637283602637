import { get, sortBy } from "lodash";
import { DataManager, Query } from "@syncfusion/ej2-data";
import {
  isActive,
  buildConnectionPermissions,
  isAllowed,
} from "../helpers/acl";
import { apiUrl } from "../http";
import DrfAdaptor from "../http/EJ2_adapter";

const initialState = () => {
  return {
    list: [],
    current: null,
    permissions: null,
  };
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  actions: {
    fetchAll(context) {
      const dm = new DataManager({
        url: apiUrl(`/api/account/connections/small/`),
        adaptor: new DrfAdaptor(),
        crossDomain: true,
      });
      return dm.executeQuery(new Query()).then((res) => {
        context.commit("setConnections", res.result);
      });
    },

    fetchById(context, connectionId) {
      return new Promise((resolve, reject) => {
        const dm = new DataManager({
          url: apiUrl(`/api/account/connections/${connectionId}`),
          adaptor: new DrfAdaptor(),
          crossDomain: true,
        });
        dm.executeQuery(new Query()).then(
          (res) => {
            const connection = res.result;
            const role = get(connection, "family.role");
            if (role) {
              const dm = new DataManager({
                url: apiUrl(`/api/auth/roles/${role.id}`),
                adaptor: new DrfAdaptor(),
                crossDomain: true,
              });
              dm.executeQuery(new Query()).then(
                (res) => {
                  const role = res.result;
                  resolve({ connection, role });
                },
                (res) => reject(res)
              );
            } else {
              resolve({ connection, role: null });
            }
          },
          (res) => reject(res)
        );
      });
    },

    fetchByRoleId(context, roleId) {
      return new Promise((resolve, reject) => {
        const dm = new DataManager({
          url: apiUrl(`/api/account/connections/`),
          adaptor: new DrfAdaptor(),
          crossDomain: true,
        });
        dm.executeQuery(
          new Query().where("family.role.id", "equal", roleId)
        ).then(
          (res) => {
            if (res.result.length === 1) {
              const connection = res.result[0];
              const dm = new DataManager({
                url: apiUrl(`/api/auth/roles/${roleId}`),
                adaptor: new DrfAdaptor(),
                crossDomain: true,
              });
              dm.executeQuery(new Query()).then((res) => {
                const role = res.result;
                resolve({ connection, role });
              });
            } else {
              console.error(`connection not found`);
              reject(res);
            }
          },
          (res) => reject(res)
        );
      });
    },

    fetchByFamilyId(context, familyId) {
      return new Promise((resolve, reject) => {
        const dm = new DataManager({
          url: apiUrl(`/api/account/connections/`),
          adaptor: new DrfAdaptor(),
          crossDomain: true,
        });
        dm.executeQuery(new Query().where("family.id", "equal", familyId)).then(
          (res) => {
            if (res.result.length === 1) {
              const connection = res.result[0];
              const roleId = get(connection, "family.role.id", null);
              if (!roleId) {
                return resolve({ connection, role: null });
              }
              const dm = new DataManager({
                url: apiUrl(`/api/auth/roles/${roleId}`),
                adaptor: new DrfAdaptor(),
                crossDomain: true,
              });
              dm.executeQuery(new Query()).then((res) => {
                const role = res.result;
                resolve({ connection, role });
              });
            } else {
              console.error(`connection not found`);
              reject(res);
            }
          },
          (res) => reject(res)
        );
      });
    },

    currentRefresh({ state, commit }) {
      const familyId = state.current.family.id;
      const dm = new DataManager({
        url: apiUrl(`/api/account/connections/`),
        adaptor: new DrfAdaptor(),
        crossDomain: true,
      });
      dm.executeQuery(new Query().where("family.id", "equal", familyId)).then(
        (res) => {
          if (res.result.length === 1) {
            commit("setCurrent", res.result[0]);
          } else {
            console.error(`connection not found`);
          }
        },
        (res) => {
          console.error(res);
        }
      );
    },
  },

  mutations: {
    setConnections: (state, data) => {
      state.list = data;
    },

    setCurrent: (state, data) => {
      state.current = data;
      state.permissions = buildConnectionPermissions(data);
    },

    resetState(state) {
      Object.assign(state, initialState());
    },
  },

  getters: {
    current: (state) => {
      return state.current;
    },

    permissions: (state) => {
      return state.permissions;
    },

    havePermission: (state) => (perm) => {
      return isAllowed(state.permissions, perm);
    },

    familyId: (state) => {
      return get(state, "current.family.id");
    },

    activeConnections: (state) => {
      return sortBy(state.list.filter(isActive), [
        (o) => {
          return o.family.name;
        },
      ]);
    },

    currencySymbol: (state) => {
      return get(
        state,
        "current.family.wallets[0].get_currency_display_short",
        "CHF"
      );
    },
  },
};
