import Vue from "vue";
import { endPoint } from "../ds";

const initialState = () => {
  return {
    emit: {
      show: false,
      invoice: null,
    },
    cash: {
      show: false,
      invoice: null,
    },
    paid: {
      show: false,
      invoice: null,
    },
    confirm: {
      show: false,
      action: null,
      invoice: null,
    },
    edit: {
      show: false,
      invoice: null,
    },
  };
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    openEmitModal(state, payload) {
      const { invoice } = payload;
      state.emit.invoice = invoice || null;
      state.emit.show = true;
    },

    closeEmitModal(state) {
      state.emit.show = false;
    },

    openCashModal(state, payload) {
      const { invoice } = payload;
      state.cash.invoice = invoice || null;
      state.cash.show = true;
    },

    closeCashModal(state) {
      state.cash.show = false;
    },

    openPaidModal(state, payload) {
      const { invoice } = payload;
      state.paid.invoice = invoice || null;
      state.paid.show = true;
    },

    closePaidModal(state) {
      state.paid.show = false;
    },

    openConfirmModal(state, payload) {
      const { action, invoice } = payload;
      state.confirm.invoice = invoice || null;
      state.confirm.action = action || null;
      state.confirm.show = true;
    },

    closeConfirmModal(state) {
      state.confirm.show = false;
    },

    openEditModal(state, payload) {
      const { invoice } = payload;
      state.edit.invoice = invoice || null;
      state.edit.show = true;
    },

    closeEditModal(state) {
      state.edit.show = false;
    },

    resetState(state) {
      Object.assign(state, initialState());
    },
  },

  actions: {
    emitInvoice(context, { roleId, invoiceId, note, data_emitted, i_number }) {
      const url = endPoint("role_invoices_toemit", [roleId], invoiceId);

      const formData = {
        i_number: i_number,
        data_emitted: data_emitted,
        note: note,
        emitted: true,
      };
      return Vue.axios.patch(url, formData);
    },

    saveInvoice(context, { roleId, invoiceId, ...rest }) {
      const url = endPoint("role_invoices_toemit", [roleId], invoiceId);

      const formData = {
        ...rest,
      };
      return Vue.axios.patch(url, formData);
    },

    cancelEmittedInvoice(context, { roleId, invoiceId }) {
      const url = endPoint("role_invoices_emitted", [roleId], invoiceId);

      const formData = {
        emitted: false,
      };
      return Vue.axios.patch(url, formData);
    },

    cashInvoice(context, { roleId, invoiceId, data_cashed }) {
      const url = endPoint("role_invoices_emitted", [roleId], invoiceId);

      const formData = {
        data_cashed: data_cashed,
        cashed: true,
      };
      return Vue.axios.patch(url, formData);
    },

    cancelCashInvoice(context, { roleId, invoiceId }) {
      const url = endPoint("role_invoices_emitted", [roleId], invoiceId);

      const formData = {
        data_cashed: null,
        cashed: false,
      };
      return Vue.axios.patch(url, formData);
    },

    paidInvoice(context, { familyId, invoiceId, data_payed }) {
      const url = endPoint("role_invoices_received", [familyId], invoiceId);

      const formData = {
        data_payed: data_payed,
        payed: true,
      };
      return Vue.axios.patch(url, formData);
    },

    cancelPaidInvoice(context, { familyId, invoiceId }) {
      const url = endPoint("role_invoices_received", [familyId], invoiceId);

      const formData = {
        data_payed: null,
        payed: false,
      };
      return Vue.axios.patch(url, formData);
    },

    setInvoiceDetailSelected(context, { roleId, invoiceId, ids, select }) {
      const url = endPoint("role_invoices_toemit_details", [roleId, invoiceId]);
      const formData = {
        ids,
        select,
      };
      return Vue.axios.post(url, formData);
    },

    editInvoice(context, { roleId, invoiceId, ...rest }) {
      const url = endPoint("role_invoices_toemit", [roleId], invoiceId);
      const formData = {
        ...rest,
      };
      return Vue.axios.patch(url, formData);
    },

    paidInvoicesBatch(context, { familyId, ids, payed }) {
      const url = endPoint("role_invoices_received", [familyId]);

      const formData = {
        ids: ids,
        payed: payed,
      };
      return Vue.axios.post(url, formData);
    },

    downloadReceivedInvoiceBatch(context, { familyId, ids }) {
      const url = endPoint("role_invoices_received", [familyId]);

      const formData = {
        ids: ids,
        pdf: true,
      };
      return Vue.axios.post(url, formData);
    },

    cashInvoicesBatch(context, { roleId, ids, cashed }) {
      const url = endPoint("role_invoices_emitted", [roleId]);

      const formData = {
        ids: ids,
        cashed: cashed,
      };
      return Vue.axios.post(url, formData);
    },

    downloadEmittedInvoiceBatch(context, { roleId, ids }) {
      const url = endPoint("role_invoices_emitted", [roleId]);

      const formData = {
        ids: ids,
        pdf: true,
      };
      return Vue.axios.post(url, formData);
    },
  },

  getters: {},
};
